import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import ContactInfo from '../utils/contactInfo'
import { oceanBlue } from '../constants/theme';
import { BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight, FaqSection } from '../utils/copperTypography';


const ContactPage = ({data}) => (
  <BasicPageContain>
    <SEO title="Contact" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>Support and FAQ</h1>
      <Grid>
        <GridLeft>
          <h3>Contact information</h3>
          <ContactInfo />
        </GridLeft>
        <GridRight>

          <h3>Copper Compression</h3>

          <FaqSection dangerouslySetInnerHTML={{__html: data.shopifyPage.body}} />

          <h2 style={{'color': oceanBlue}}>For prompt support please use the contact widget (The Copper Envelope) in the bottom right corner, or email us directly. Thank You!</h2>

          <p className="wide">We back all of our products with a money-back guarantee. If you are not completely satisfied with this product for any reason, CopperCompression.com (a Copper Compression, LLC site) will gladly refund your purchase price, excluding shipping and handling charges, within 30 days of purchase.</p>
          <Link to="/" className="mt40">Go back to the homepage</Link>
        </GridRight>
      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
)

export const query = graphql`
query FaqContentQuery {
  shopifyPage(handle: {eq: "faq"}) {
    body
    updatedAt
  }
}
`

export default ContactPage
